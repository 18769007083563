<template>
    <ui-card v-if="threeds" class="threeds-information" title="3DS information">
        <ui-list>
            <ui-list-item title="Action:" v-if="threeds.action">
                <template #text>
                    {{ threeds.action }}
                </template>
            </ui-list-item>
            <ui-list-item title="Reference id:" v-if="threeds.reference_id">
                <template #text>
                    {{ threeds.reference_id }}
                </template>
            </ui-list-item>
            <ui-list-item title="Enrollment id:" v-if="threeds.enrollment_id">
                <template #text>
                    {{ threeds.enrollment_id }}
                </template>
            </ui-list-item>
            <ui-list-item title="Status:" v-if="threeds.status">
                <template #text>
                    <ui-status :status="threeds.status"></ui-status>
                </template>
            </ui-list-item>
            <ui-list-item title="Protocol:" v-if="threeds.indicators.protocol">
                <template #text>
                    {{ threeds.indicators.protocol }}
                </template>
            </ui-list-item>
            <ui-list-item title="Version:" v-if="threeds.indicators.version">
                <template #text>
                    {{ threeds.indicators.version }}
                </template>
            </ui-list-item>
            <ui-list-item title="Cavv:" v-if="threeds.indicators.cavv">
                <template #text>
                    {{ threeds.indicators.cavv }}
                </template>
            </ui-list-item>
            <ui-list-item title="Eci:" v-if="threeds.indicators.eci">
                <template #text>
                    {{ threeds.indicators.eci }}
                </template>
            </ui-list-item>
            <ui-list-item title="XID:" v-if="threeds.indicators.xid">
                <template #text>
                    {{ threeds.indicators.xid }}
                </template>
            </ui-list-item>
            <ui-list-item title="DS Transaction id:" v-if="threeds.indicators.ds_transaction_id">
                <template #text>
                    {{ threeds.indicators.ds_transaction_id }}
                </template>
            </ui-list-item>
        </ui-list>
    </ui-card>
</template>

<script>
import UiCard from '@/components/ui/Card.vue';
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import UiStatus from '@/components/ui/BadgeStatus.vue';

export default {
    components: {
        UiCard,
        UiList,
        UiListItem,
        UiStatus
    },
    props: {
        threeds: { 
            type: Object, 
            default: null 
        },
    },
}
</script>

<style lang="scss">
.threeds-information {
    &__list {
        display: flex;
        flex-direction: column;
    }

    &__list-item {
        display: flex;
        flex-wrap: wrap;
        padding: 12px 0px;

        @media (max-width: 540px) {
            display: initial;
        }
        
        &:not(:last-child) {
            border-bottom: 1px solid $ecart-secondary-100;
        }
    }

    &__list-title {
        color: $ecart-secondary-500;
        font-weight: 500;
        width: 150px;
        max-width: 100%;
    }
    
    &__list-text {
        font-weight: 500;
    }

    &__contact-buttons {
        display: flex;
        gap: 5px;
    }
}
</style>